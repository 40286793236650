<template>
  <div class="imageCarousel" v-if="show">
    <div class="image-box">
      <div class="box-close" @click="close">x</div>
      <div class="image-left" @click="goBack()">
        <div class="image-left-arrow" v-if="canBack"></div>
      </div>
      <div class="image-content" :style="{'background': `url(${imageList[currentIndex]}`}"></div>
      <div class="image-right" @click="goNext()">
        <div class="image-right-arrow" v-if="canGo"></div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "ImageCarousel",
  data() {
    return {}
  },
  computed: {
    ...mapState({
      show: state => state.modal.imageCarousel.show,
      imageList: state => state.modal.imageCarousel.imageList,
      currentIndex: state => state.modal.imageCarousel.currentIndex,
    }),
    canBack() {
      return this.currentIndex > 0
    },
    canGo() {
      return this.currentIndex < this.imageList.length - 1
    },
  },
  methods: {
    ...mapActions([
      'changeImageCarouselState',
    ]),
    goBack() {
      if (this.canBack) {
        this.changeImageCarouselState({
          currentIndex: this.currentIndex - 1,
          imageList: this.imageList,
          show: true,
        })
      }
    },
    close() {
      this.changeImageCarouselState({
        currentIndex: 0,
        imageList: [],
        show: false,
      })
    },
    goNext() {
      if (this.canGo) {
        this.changeImageCarouselState({
          currentIndex: this.currentIndex + 1,
          imageList: this.imageList,
          show: true,
        })
      }
    }
  },
}
</script>

<style scoped lang="less">
.imageCarousel {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
}

.box-close {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 40px;
  cursor: pointer;
}

.image-box {
  width: 500px;
  height: 500px;
  border-radius: 12px;
  background-color: #fff;
  position: relative;
  display: flex;
  align-items: center;
}

.image-left {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.image-left-arrow {
  width: 15px;
  height: 26px;
  background: url("../../assets/images/ic_goback.png") no-repeat;
  transform: rotate(180deg);
  background-size: cover;
}

.image-right-arrow {
  width: 15px;
  height: 26px;
  background: url("../../assets/images/ic_goback.png") no-repeat;
  background-size: cover;
}

.image-right {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.image-content {
  flex: 1;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  height: 380px;
}

</style>
