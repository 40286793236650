<template>
   
    <el-menu
        default-active="1"
        @open="handleOpen"
        @close="handleClose"
        background-color="#324157"
        text-color="#fff"
        :collapse="sidebar.opened"
        router
        active-text-color="#ffd04b">
      <template v-for="menu in menuData">

        <el-submenu :key="menu.index" v-if="menu.children&&menu.children.length>0" :index="menu.index">
          <template slot="title">
            <i :class="menu.icon"></i>
            <span slot="title">{{ menu.title }}</span>
          </template>

          <el-menu-item v-for="child in menu.children" :route="{path:child.path}" :key="child.index"
                        :index="child.index">
            <i :class="child.icon"></i>
            <span slot="title">{{ child.title }}</span>
          </el-menu-item>

        </el-submenu>

        <el-menu-item v-else :route="{path:menu.path}" :index="menu.index">
          <i :class="menu.icon"></i>
          <span slot="title">{{ menu.title }}</span>
        </el-menu-item>

      </template>
    </el-menu>
   
</template>

<script>
import {mapState} from "vuex";

import shortUUID from "short-uuid"

export default {
  name: "SideBar",
  data() {
    return {
      index: '',
      isChecked: true,
      menuData: []
    }
  },
  components: {},
  watch: {
    $route(to, from) {
      // console.log('路由变更', to,from)
      this.isChecked = to.name === 'main'
      // this.watchRouter()
    },
  },
  computed: {
    ...mapState('modal', ['sidebar']),
    ...mapState('user', ['userInfo'])
  },
  methods: {
    handleClose(key) {
      // console.log(key)
    },
    handleOpen(index) {
      // console.log(index)
    },
  },
  mounted() {
    let menuData = []
    this.$router.options.routes.map(route => {
      if (route.meta && route.meta.sideMenu) {
        const menu = {
          index: shortUUID.generate(),
          path: route.path,
          ...route.meta
        }
        //是否有子路由
        if (route.children) {
          let children = []
          route.children.map(child => {
            if (child.meta && child.meta.sideMenu) {
              const childMenu = {
                index: shortUUID.generate(),
                path: child.path,
                ...child.meta
              }
              children.push(childMenu)
            }
          })
          menu.children = children
        }
        menuData.push(menu)
      }
    })
    this.menuData = menuData
    // console.log('侧边栏菜单数据', menuData)
  },
}
</script>

<style scoped lang="less">
a:hover, a:visited, a:link, a:active {
  text-decoration: none;
}
</style>
