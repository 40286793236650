<template>
  <div class="appMain" :class="{appMainHideSliderBar:sidebar.opened}">
    <TabComponent/>
     
    <keep-alive>
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
    </keep-alive>
     
  </div>
</template>

<script>
import {mapState} from "vuex";
import TabComponent from "@/components/home/TabComponent";

export default {
  name: "AppMain",
  components: {
    TabComponent,
  },
  data() {
    return {
      showBg: ['/home/jdStatusDetail', '/home/paymentDetail', '/home/supplyNoDetail', '/home/supplyPriceDetail', '/home/orderTypeDetail', '/home/categoryIdDetail', '/home/duplicateSplitDetail'],
      isShow: true,
      editableTabsValue: '2',
      editableTabs: [{
        title: 'Tab 1',
        name: '1',
        content: 'Tab 1 content'
      }, {
        title: 'Tab 2',
        name: '2',
        content: 'Tab 2 content'
      }],
      tabIndex: 2
    }
  },
  computed: {
    ...mapState('modal', ['sidebar']),
  },
  watch: {
    $route(to, from) {
      if (this.showBg.indexOf(this.$route.path) !== -1) {
        this.isShow = false
      } else {
        this.isShow = true
      }
    },
  }, 
  created() {
    if (this.showBg.indexOf(this.$route.path) !== -1) {
      this.isShow = false
    }
  },
  methods: {
    addTab(targetName) {
      let newTabName = ++this.tabIndex + '';
      this.editableTabs.push({
        title: 'New Tab',
        name: newTabName,
        content: 'New Tab content'
      });
      this.editableTabsValue = newTabName;
    },
    removeTab(targetName) {
      let tabs = this.editableTabs;
      let activeName = this.editableTabsValue;
      if (activeName === targetName) {
        tabs.forEach((tab, index) => {
          if (tab.name === targetName) {
            let nextTab = tabs[index + 1] || tabs[index - 1];
            if (nextTab) {
              activeName = nextTab.name;
            }
          }
        });
      }
      this.editableTabsValue = activeName;
      this.editableTabs = tabs.filter(tab => tab.name !== targetName);
    }
  }
}
</script>

<style scoped>
.appMain {
  flex: 1;
  display: flex;  
  flex-direction: column;
  overflow: hidden;
}

.el-tabs {
  margin-top: 10px;
}

.appMainHideSliderBar {
  left: 64px;
}

</style>
