<template>
  <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="600px"
      center
  >
    <el-form :model="formData" label-width="80px" :rules="rules" ref="formData">
      <el-form-item label="类型名称" prop="name">
        <el-input v-model="formData.name" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="排序" prop="sort">
        <el-input v-model.number="formData.sort" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="状态" prop="enable">
        <el-switch v-model="formData.enable"></el-switch>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="submitForm('formData')">确 定</el-button>
    </span>
  </el-dialog>

</template>

<script>
import {Loading} from "element-ui";
import axios from "@/net/axios";
import api from "@/net/api";

export default {
  name: "AddModifyCategoryDialog",
  props: ['reload', 'type'],
  data() {
    return {
      formData: {
        name: '',
        enable: '',
        sort: 0,
      },
      dialogVisible: false,
      rules: {
        name: [
          {required: true, message: '请输入类型名称', trigger: 'blur'},
          {min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'blur'}
        ],
        sort: [
          {type: 'number', message: '排序必须为数字值'}
        ],
      },
      title: '',
    }
  },
  methods: {
    show(data) {
      this.dialogVisible = true
      if (data) {
        this.title = '修改分类'
        this.formData.name = data.name;
        this.formData.sort = data.sort;
        this.formData.id = data.id;
        this.formData.enable = data.enable == 1;
      } else {
        this.title = '新增分类'
        this.formData.name = '';
        this.formData.sort = 0;
        this.formData.id = null;
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.formData.id) {
            this.updateType();
          } else {
            this.createType();
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    createType() {
      let loadingInstance = Loading.service({background: 'rgba(0, 0, 0, 0.3)'});
      let url
      switch (this.type) {
        case 1:
          url = api.createCaseType;
          break
        case 2:
          url = api.createNewsType;
          break
        default:
          url = api.createAdvertiseType;
          break
      }
      axios.post(url, this.formData)
          .then(res => {
            loadingInstance.close();
            res.message && this.$message.success(res.message);
            this.dialogVisible = false
            this.reload()
          })
          .catch(e => {
            loadingInstance.close();
            e.message && this.$message.error(e.message);
          })
    },
    updateType() {
      let loadingInstance = Loading.service({background: 'rgba(0, 0, 0, 0.3)'});
      let url
      switch (this.type) {
        case 1:
          url = api.updateCaseType;
          break
        case 2:
          url = api.updateNewsType;
          break
        default:
          url = api.updateAdvertiseType;
          break
      }

      axios.post(url, {...this.formData, state: this.formData.enable ? 1 : 2})
          .then(res => {
            loadingInstance.close();
            res.message && this.$message.success(res.message);
            this.dialogVisible = false
            this.reload()
          })
          .catch(e => {
            loadingInstance.close();
            e.message && this.$message.error(e.message);
          })
    }
  }
}
</script>

<style scoped>

</style>
