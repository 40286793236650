<template>
  <div class="home-container">
    <el-card class="platform-info" shadow="hover">
      <div slot="header">
        <span class="title">管理中心</span>
      </div>
      <div class="platform-info-content">
        <div class="user-avatar"></div>
        <div class="info">
          <span>{{userInfo.realName}}，欢迎进入管理中心！</span>
          <span>上次登录：{{userInfo.updatedAt|format()}}</span>
        </div>
      </div>
    </el-card>
    <el-card class="company-info" shadow="hover">
      <div slot="header" class="header" @click="$refs.editCompanyInfoDialog.show()">
        <span class="title ">企业信息 <i class="el-icon-edit"></i></span>
      </div>
      <div class="company-info-content">
        <img src="../../assets/images/logo_main.png">
        <div>
          <h3>公司名称：{{systemInfo.companyName}}</h3>
          <h3>公司地址：{{systemInfo.address}}</h3>
          <h3>联系电话：{{systemInfo.phone}}</h3>
          <h3>电子邮箱：{{systemInfo.email}}</h3>
        </div>
      </div>
    </el-card>
    <EditCompanyInfoDialog title="编辑公司信息" ref="editCompanyInfoDialog"/>
  </div>
</template>

<script>
import EditCompanyInfoDialog from "@/components/home/EditCompanyInfoDialog";
import {mapActions, mapState} from "vuex";
import moment from 'moment'
export default {
  name: "Home",
  components: {EditCompanyInfoDialog},
  computed: {
    ...mapState('user', ['userInfo']),
    ...mapState('systemInfo', ['systemInfo']), 
  },
  data() {
    return {}
  },
  methods:{
    ...mapActions('user', ['getUserInfo']),
    ...mapActions('systemInfo', ['getSystemInfo'])
  },
  mounted() {
    this.getUserInfo()
    this.getSystemInfo()
  },
  filters:{
    format(val){
      return moment(val).format('YYYY-MM-DD HH:mm:ss')
    }
  }
}
</script>

<style scoped lang="less">
.home-container {
  margin: 20px;
}

.user-avatar {
  display: inline-block;
  background: url("../../assets/images/ic_avatar.png") no-repeat;
  width: 80px;
  height: 80px;
  background-size: cover;
  margin-right: 5px;
  vertical-align: middle;
  font-weight: bold !important;
  font-size: 16px !important;
}

.company-info {
  margin-top: 20px;

  &-content {
    img {
      width: 200px;
    }

    div {
      margin-top: 20px;

      h3 {
        line-height: 25px;
        font-size: 14px;
      }
    }
  }
}

.title {
  font-size: 16px;
  color: #3c3c3c;
  font-weight: bold;
  margin-right: 10px;
}

.company-info .title:hover {
  cursor: pointer;
  color: #409EFF;
}

.platform-info-content {
  display: flex;
  align-items: center;

  .info {
    display: flex;
    flex-direction: column;
    margin-left: 20px;

    span:first-child {
      height: 18px;
      font-size: 18px;
      color: #3c3c3c;
    }

    span:last-child {
      height: 14px;
      font-size: 14px;
      color: #676a6c;
      margin-top: 10px;
    }
  }
}
</style>
