<template>
  <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="600px"
      center
  >
    <el-form :model="formData" label-width="80px" label-position="left" :rules="rules" ref="formData">
      <el-form-item label="账号" prop="userName">
        <el-input v-model="formData.userName" :disabled="!!formData.id" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="姓名" prop="realName">
        <el-input v-model="formData.realName" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="手机号">
        <el-input v-model="formData.phone" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item v-if="!!formData.id" label="状态" prop="enable">
        <el-switch v-model="formData.enable"></el-switch>
      </el-form-item>
      <el-form-item v-if="!formData.id" label="密码" prop="password"
                    :rules="[
                              {required: true, message: '请输入密码', trigger: 'blur'},
                              {min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'blur'}
                            ]"
      >
        <el-input type="password" v-model="formData.password" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item v-if="!formData.id" label="确认密码" prop="checkPass"
                    :rules="[
                      {required: true, validator: validatePass, trigger: 'blur'},
                      {min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'blur'}
                    ]"
      >
        <el-input type="password" v-model="formData.checkPass" autocomplete="off"></el-input>
      </el-form-item>
      
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="submitForm('formData')">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {Loading} from "element-ui";
import axios from "@/net/axios";
import api from "@/net/api";

export default {
  name: "AddModifyAccountDialog",
  props: ['reload'],
  data() {
    return {
      formData: {
        userName: '',
        realName: '',
        phone: '',
        password: '',
        checkPass: '',
        id: null,
        enable:true,
      },
      dialogVisible: false,
      rules: {
        userName: [
          {required: true, message: '请输入账号', trigger: 'blur'},
          {min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'blur'}
        ],
        realName: [
          {required: true, message: '请输入真实姓名', trigger: 'blur'},
          {min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'blur'}
        ],
      },
      title: '',
    }
  },
  methods: {
    show(data) {
      this.dialogVisible = true
      if (data) {
        this.title = '修改账号'
        this.formData.userName = data.userName;
        this.formData.realName = data.realName;
        this.formData.phone = data.phone;
        this.formData.id = data.id;
        this.formData.enable = data.state==1;
      } else {
        this.title = '创建账号'
        this.formData.userName = '';
        this.formData.realName = '';
        this.formData.phone = '';
        this.formData.password = '';
        this.formData.checkPass = '';
        this.formData.id = null;
      }
    },
    validatePass(rule, value, callback)  {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.formData.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.formData.id) {
            this.updateAccount();
          } else {
            this.createAccount();
          }
        } else {
          console.log('验证',valid)
          return false;
        }
      });
    },
    createAccount() {
      let loadingInstance = Loading.service({background: 'rgba(0, 0, 0, 0.3)'});
      axios.post(api.createAccount, this.formData)
          .then(res => {
            loadingInstance.close();
            res.message && this.$message.success(res.message);
            this.dialogVisible = false
            this.reload()
          })
          .catch(e => {
            loadingInstance.close();
            e.message && this.$message.error(e.message);
          })
    },
    updateAccount() {
      let loadingInstance = Loading.service({background: 'rgba(0, 0, 0, 0.3)'});
      axios.post(api.updateAccount, {...this.formData,state:this.formData.enable?1:2})
          .then(res => {
            loadingInstance.close();
            res.message && this.$message.success(res.message);
            this.dialogVisible = false
            this.reload()
          })
          .catch(e => {
            loadingInstance.close();
            e.message && this.$message.error(e.message);
          })
    }
  }
}
</script>

<style scoped>

</style>
