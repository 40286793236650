import Vue from 'vue'
// 该文件专门用于创建整个应用的路由器
import VueRouter from 'vue-router'
import Login from '../pages/Login'
import Home from "../pages/home";
import Case from "../pages/case";
import CaseCategory from "../pages/case/CaseCategory";
import CaseList from "../pages/case/CaseList";
import AddModifyCase from "../pages/case/AddModifyCase";
import store from '../store'
import Account from "@/pages/account";
import AccountList from "@/pages/account/AccountList";
import News from "@/pages/news";
import NewsList from "@/pages/news/NewsList";
import NewsCategory from "@/pages/news/NewsCategory";
import AddModifyNews from "@/pages/news/AddModifyNews";
import Advertise from "@/pages/advertise";
import AdvertiseList from "@/pages/advertise/AdvertiseList";
import AdvertiseCategory from "@/pages/advertise/AdvertiseCategory";
import AddModifyAdvertise from "@/pages/advertise/AddModifyAdvertise";

Vue.use(VueRouter)
//创建并暴露一个路由器
/**
 * 设置meta对象中的sideMenu=true，即可在侧边菜单栏显示,icon是图标,title即标题
 * @type {VueRouter}
 */
const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'index',
            redirect: '/login',
            children: []
        },
        {
            path: '/login',
            component: Login,
            name: 'login',
            meta: {title: '用户登录'},
        },
        {
            path: '/home',
            component: Home,
            name: 'home',
            meta: {title: '管理平台', icon: 'el-icon-menu', sideMenu: true},
        }
        ,
        {
            path: '/account',
            component: Account,
            name: 'account',
            meta: {title: '账号管理', icon: 'el-icon-user-solid', sideMenu: true},
            redirect: '/account/list',
            children: [
                // {
                //     path: '/account/role',
                //     component: AccountList,
                //     name: 'roseList',
                //     meta: {title: '角色权限管理', icon: 'el-icon-document', sideMenu: true},
                // },
                // {
                //     path: '/account/permission',
                //     component: AddModifyCase,
                //     name: 'permission',
                //     meta: {title: '权限管理', icon: 'el-icon-document', sideMenu: true},
                // },
                {
                    path: '/account/list',
                    component: AccountList,
                    name: 'accountList',
                    meta: {title: '账号列表', icon: 'el-icon-document', sideMenu: true},
                },
            ]
        },
        {
            path: '/case',
            component: Case,
            name: 'case',
            meta: {title: '案例管理', icon: 'el-icon-files', sideMenu: true},
            redirect: '/case/list',
            children: [
                {
                    path: '/case/list',
                    component: CaseList,
                    name: 'caseList',
                    meta: {title: '案例列表', icon: 'el-icon-document', sideMenu: true},
                },
                {
                    path: '/case/category',
                    component: CaseCategory,
                    name: 'caseCategory',
                    meta: {title: '案例分类', icon: 'el-icon-document', sideMenu: true},
                },
                {
                    path: '/case/create',
                    component: AddModifyCase,
                    name: 'createCase',
                    props:true,
                    meta: {title: '新增案例'},
                }, 
                {
                    path: '/case/modify',
                    component: AddModifyCase,
                    name: 'modifyCase',
                    props:true,
                    meta: {title: '修改案例'},
                },
            ]
        },
        {
            path: '/news',
            component: News,
            name: 'news',
            meta: {title: '咨询管理', icon: 'el-icon-s-cooperation', sideMenu: true},
            redirect: '/news/list',
            children: [
                {
                    path: '/news/list',
                    component: NewsList,
                    name: 'caseList',
                    meta: {title: '咨询列表', icon: 'el-icon-document', sideMenu: true},
                    children: [
                        ]
                },
                {
                    path: '/news/category',
                    component: NewsCategory,
                    name: 'caseCategory',
                    meta: {title: '咨询分类', icon: 'el-icon-document', sideMenu: true},
                },
                {
                    path: '/news/create',
                    component: AddModifyNews,
                    name: 'createNews',
                    props:true,
                    meta: {title: '新增咨询'},
                },
                {
                    path: '/news/modify',
                    component: AddModifyNews,
                    name: 'modifyNews',
                    props:true,
                    meta: {title: '修改咨询'},
                },
            ]
        },
        {
            path: '/advertise',
            component: Advertise,
            name: 'advertise',
            meta: {title: '广告管理', icon: 'el-icon-s-cooperation', sideMenu: true},
            redirect: '/advertise/list',
            children: [
                {
                    path: '/advertise/list',
                    component: AdvertiseList,
                    name: 'advertiseList',
                    meta: {title: '广告列表', icon: 'el-icon-document', sideMenu: true},
                    children: [
                        ]
                },
                {
                    path: '/advertise/category',
                    component: AdvertiseCategory,
                    name: 'advertiseCategory',
                    meta: {title: '广告分类', icon: 'el-icon-document', sideMenu: true},
                },
                {
                    path: '/advertise/create',
                    component: AddModifyAdvertise,
                    name: 'createAdvertise',
                    props:true,
                    meta: {title: '新增广告'},
                },
                {
                    path: '/advertise/modify',
                    component: AddModifyAdvertise,
                    name: 'modifyAdvertise',
                    props:true,
                    meta: {title: '修改广告'},
                },
            ]
        }
        
    ]
})

//全局后置路由守卫————初始化的时候被调用、每次路由切换之后被调用
router.afterEach((to, from) => {
    //切换路由，页面直接滚动到顶部
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
})

//全局前置路由守卫————初始化的时候被调用、每次路由切换之前被调用
router.beforeEach((to, from, next) => {
    // console.log('前置路由守卫',to,from,store)
    if (to.name == 'index' || to.name == 'login') {
        store.commit('page/setIsMain', false)
    } else {
        store.commit('page/setIsMain', true)
    }

    // if(to.meta.isAuth){ //判断是否需要鉴权
    //     if(localStorage.getItem('school')==='atguigu'){
    //         next()
    //     }else{
    //         alert('学校名不对，无权限查看！')
    //     }
    // }else{
    //     next()
    // }

    next()
})

export default router
