<template>
  <div class="edit-container">
    <div class="msg-view">
      <div class="msg-title">
        <i class="el-icon-info"></i>
        <span>基本信息</span>
      </div>
      <el-form :model="formData" :rules="rules" ref="formData" label-width="100px" class="formData"
               :inline-message="true">
        <el-form-item label="标题:" prop="title">
          <el-input v-model="formData.title"></el-input>
        </el-form-item>
        <el-form-item label="类型:" prop="advertiseType">
          <el-select v-model="formData.advertiseType" placeholder="类型">
            <el-option v-for="item in advertiseTypeList" :label="item.name" :value="item.id" :key="item.id"></el-option>
          </el-select>
        </el-form-item> 
        <el-form-item label="图片:" prop="picUrl">
          <el-upload
              :action="uploadUrl()"
              list-type="picture-card"
              :show-file-list="false"
              :headers="getHeaders()"
              :on-success="handleSuccess"
               >
            <img v-if="formData.picUrl" :src="formData.picUrl" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="跳转地址:" >
          <el-input v-model="formData.url"></el-input>
        </el-form-item>
        <el-form-item label="排序:" prop="sort">
          <el-input v-model.number="formData.sort" autocomplete="off"></el-input>
        </el-form-item> 
        <el-form-item label="状态:">
          <el-switch v-model="formData.state"></el-switch>
        </el-form-item>
        <el-form-item>
          <el-button plain type="primary" @click="submitForm('formData')" >提交</el-button>
          <el-button plain @click="goBack()" >返回</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
/**
 * 添加修改资讯
 */
import {Loading} from "element-ui";
import axios from "@/net/axios";
import api from "@/net/api";
import {isNullOrEmpty} from "@/utils";
import pubsub from 'pubsub-js'
export default {
  name: "AddModifyAdvertise",
  data() {
    return {
      formData: {
        recommend: false,
        state: true,
        title: '',
        url: '', 
        content: '',
        sort: 0,
        advertiseType: '',
        picUrl: null,
        id: null,
      },
      rules: {
        title: [
          {required: true, message: '请输入标题', trigger: 'blur'},
          {min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'blur'}
        ],
        advertiseType: [
          {required: true, message: '请选择类型', trigger: 'blur'}
        ],
        picUrl: [
          {required: true, message: '请上传图片', trigger: 'blur'}
        ],
        sort: [
          {type: 'number', message: '排序必须为数字值'}
        ],
      },
      advertiseTypeList: []
    }
  },
 
  mounted() {
    // console.log('路由信息',this.$route)
    if (!isNullOrEmpty(this.$route.query)) {
      this.formData.title = this.$route.query.title;
      this.formData.sort = this.$route.query.sort;
      this.formData.id = this.$route.query.id;
      this.formData.url = this.$route.query.url;
      this.formData.picUrl = this.$route.query.picUrl;
      this.formData.content = this.$route.query.content;
      this.formData.advertiseType = this.$route.query.typeId;
      this.formData.enable = this.$route.query.state == 1;
      this.formData.recommend = this.$route.query.recommend;
    }
    this.loadTypeList()
  },
  methods: {
    goBack() {
      console.log('点击返回',this.$route.path)
      pubsub.publish('closeTab',this.$route.path)
    },
    uploadUrl() {
      return location.origin + api.uploadFile;
    },
    getHeaders() {
      return {authorization: window.token}
    },
    handleSuccess(res, file) {
      this.formData.picUrl = res.data.url;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.formData.id) {
            this.updateAdvertise();
          } else {
            this.createAdvertise();
          }
        } else {
          return false;
        }
      });
    },
    createAdvertise() {
      let loadingInstance = Loading.service({background: 'rgba(0, 0, 0, 0.3)'});
      axios.post(api.createAdvertise, this.formData)
          .then(res => {
            loadingInstance.close();
            res.message && this.$message.success(res.message);
            this.dialogVisible = false
            this.$router.back()
            pubsub.publish('closeTab',this.$route.path)
            this.$bus.$emit('reloadAdList')
          })
          .catch(e => {
            loadingInstance.close();
            e.message && this.$message.error(e.message);
          })
    },
    updateAdvertise() {
      let loadingInstance = Loading.service({background: 'rgba(0, 0, 0, 0.3)'});
      axios.post(api.updateAdvertise, {...this.formData, state: this.formData.enable ? 1 : 2})
          .then(res => {
            loadingInstance.close();
            res.message && this.$message.success(res.message);
            this.dialogVisible = false
            pubsub.publish('closeTab',this.$route.path)
            this.$bus.$emit('reloadAdList')
          })
          .catch(e => {
            loadingInstance.close();
            e.message && this.$message.error(e.message);
          })
    },
    loadTypeList() {
      axios.get(api.advertiseEnableTypeList)
          .then(res => {
            this.advertiseTypeList = res.data;
          })
          .catch(console.log)
    },
  },
}
</script>

<style scoped lang="less">
.edit-container {
  padding: 20px;
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
}

.msg-view {
  flex: 1;
}

.msg-title {
  padding-bottom: 10px;
  border-bottom: 1px solid #E4E7ED;
  width: 100%;
  font-weight: bold;
  font-size: 14px;
  span {
    margin-left: 10px;
  }
}

.formData {
  padding: 30px 0 10px 0;
}

.el-input {
  /deep/ .el-input__inner {
    width: 300px;
  }
}

.el-textarea {
  /deep/ .el-textarea__inner {
    width: 500px;
  }
}

.quill-editor {
  /deep/ .ql-container {
    min-height: 300px !important;
  }
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 148px;
  height: 148px;
  line-height: 148px;
  text-align: center;
}

.avatar {
  width: 148px;
  height: 148px;
  display: block;
}
</style>
