import Vue from 'vue'
import App from './App.vue' 
import store from './store'
import router from './router'
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
//富文本编辑器
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import moment from "moment";

Vue.config.productionTip = false
 
Vue.use(ElementUI)
Vue.use(VueToast)
Vue.use(VueQuillEditor)
Vue.filter('timeFormat',function(value,str='YYYY-MM-DD HH:mm:ss'){
        return moment(value).format(str)
})
new Vue({
    el:'#app',
    render: h => h(App),
    beforeCreate() {
        Vue.prototype.$bus = this //安装全局事件总线
    },
    router,
    store,
})
