import { render, staticRenderFns } from "./AddModifyCategoryDialog.vue?vue&type=template&id=45cc4f48&scoped=true&"
import script from "./AddModifyCategoryDialog.vue?vue&type=script&lang=js&"
export * from "./AddModifyCategoryDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45cc4f48",
  null
  
)

export default component.exports