<template>
  <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="600px"
      center
  >
    <el-form :model="form">
      <el-form-item label="公司名称" :label-width="formLabelWidth">
        <el-input v-model="form.companyName" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="公司地址" :label-width="formLabelWidth">
        <el-input v-model="form.address" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="联系电话" :label-width="formLabelWidth">
        <el-input v-model="form.phone" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="电子邮箱" :label-width="formLabelWidth">
        <el-input v-model="form.email" autocomplete="off"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="submitForm()">确 定</el-button>
    </span>
  </el-dialog> 
</template>

<script>
import {Loading} from "element-ui";
import axios from "@/net/axios";
import api from "@/net/api";
import {mapActions, mapState} from "vuex";

export default {
  name: "EditCompanyInfoDialog",
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      form: {
        companyName: '',
        address: '',
        phone: '',
        email: '',
      },
      formLabelWidth: '120px',
      dialogVisible: false
    }
  },
  computed: {
    ...mapState('systemInfo', ['systemInfo']),
  },
  watch: {
    systemInfo(val, oldValue) {
      console.log('系统信息',val,oldValue)
      this.form.companyName = val.companyName || ''
      this.form.address = val.address || ''
      this.form.phone = val.phone || ''
      this.form.email = val.email || ''
    }
  },
  methods: {
    ...mapActions('systemInfo', ['getSystemInfo']),
    show() {
      this.dialogVisible = true
    },
    submitForm() {
      let loadingInstance = Loading.service({background: 'rgba(0, 0, 0, 0.3)'});
      axios.post(api.updateSysInfo, this.form)
          .then(res => {
            loadingInstance.close();
            res.message && this.$message.success(res.message);
            this.dialogVisible = false
            this.getSystemInfo()
          })
          .catch(e => {
            loadingInstance.close();
            e.message && this.$message.error(e.message);
          })
    },
  },

}
</script>

<style scoped>

</style>
