<template>
  <div class="navbar">
    <Hamburger class="hamburger-container" :toggleClick="toggleClick" :isActive="sidebar.opened"></Hamburger>
    <el-dropdown class="avatar-container" trigger="click">
      <span class="loginInfo">{{ userInfo.userName }}
        <div class="user-avatar"></div>
      </span>
      <i class="el-icon-caret-bottom"></i>
      <el-dropdown-menu class="user-dropdown" slot="dropdown">
        <el-dropdown-item><span @click="quit">退出登录</span></el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import Hamburger from "./Hamburger";
import {mapActions, mapState} from "vuex";

export default {
  name: "NavBar",
  components: {Hamburger},
  computed: {
    ...mapState('modal', ['sidebar']),
    ...mapState('user', ['userInfo'])
  },
  
  methods: {
    ...mapActions('modal',{changeSiderbarState:'changeSiderbarState'}),
    
    quit() {
      let query = {
        data: {},
      }
      // quitUser(query).then(response => {
      //   let data = response.data
      //   if (data.code === 200) {
      //     sessionStorage.clear()
          this.$router.push({path: '/login'})
      //   }
      // })
    },
    toggleClick() {
      this.changeSiderbarState({opened: !this.sidebar.opened})
    },
  },
}
</script>

<style scoped lang="less">
.navbar {
  height: 51px;
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 0 25px 0 15px; 
  border-bottom: 1px solid #E4E7ED;
}

.hamburger {
  display: inline-block;
  float: left;
  cursor: pointer;
  width: 20px;
  height: 20px;
  transform: rotate(0deg);
  transition: .38s;
  transform-origin: 50% 50%;
}

.hamburger.is-active {
  transform: rotate(90deg);
}

.avatar-container {
  position: fixed;
  right: 15px;
  cursor: pointer;
}

.user-avatar {
  display: inline-block;
  background: url("../../assets/images/ic_avatar.png") no-repeat;
  width: 40px;
  height: 40px;
  background-size: cover;
  margin-right: 5px;
  vertical-align: middle;
  font-weight: bold !important;
  font-size: 16px !important;
}
.loginInfo{
  vertical-align: middle;
  font-weight: bold !important;
  font-size: 16px !important;
}

</style>
