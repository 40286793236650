<template>
  <div class="login-container">
    <div class="logo"><img src="../assets/images/login_logo.png"/></div>
    <h1>管理系统</h1>
    <el-form :model="formData" ref="formData" :rules="rules" class="form-style">
      <el-form-item prop="userName">
        <el-input v-model="formData.userName" placeholder="请输入用户名" suffix-icon="el-icon-user"></el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input type="password" v-model="formData.password" placeholder="请输入密码" suffix-icon="el-icon-lock"></el-input>
      </el-form-item>
      <el-button type="primary" class="btn-login"  @click="submitForm('formData')">登录</el-button>
    </el-form>
  </div>
</template>

<script>
import axios from '../net/axios'
import api from '../net/api'
import { Loading } from 'element-ui';
import Auth from "@/utils/auth";
export default {
  name: "Login",
  data() {
    return {
      formData: {
        userName: '',
        password: '',
      },
      rules: {
        userName: [
          {required: true, message: '请输入用户名', trigger: 'blur'}
        ],
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'},
        ],
      },
      loading:false
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let loadingInstance = Loading.service({background:'rgba(0, 0, 0, 0.3)'});
          axios.post(api.login, this.formData)
              .then(res => {
                loadingInstance.close();
                console.log('登录成功',res)
                Auth.setToken(res.data)
                window.token=res.data
                this.$router.replace({name:'home'})
              })
              .catch(e => {
                loadingInstance.close();
                e.message && this.$message.error(e.message);
              })
        } else {
          return false;
        }
      });
    },

  }
}
</script>

<style scoped lang="less">
.login-container {
  width: 100%;
  height: 100%;
  background: url("../assets/images/bg_login.jpg") no-repeat;
  background-size: cover;
  position: fixed;


  .logo {
    margin: 100px auto 30px;
    width: 200px;

    img {
      width: 200px;
    }
  }

  h1 {
    color: white;
    font-size: 20px;
    text-align: center;
  }
}

.form-style {
  margin: 80px auto 0;
  width: 300px;
}

.btn-login {
  width: 100%;
}

</style>
