<template>
  <div class="list-container">
    <div class="list-header">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item>
          <el-input v-model="formInline.keywords" placeholder="账号/姓名/id"></el-input>
        </el-form-item>
        <!--        <el-form-item>-->
        <!--          <el-select v-model="formInline.roleType" placeholder="角色类型" @change="getData">-->
        <!--            <el-option label="交易" value="交易"></el-option>-->
        <!--            <el-option label="退款" value="退款"></el-option>-->
        <!--          </el-select>-->
        <!--        </el-form-item>-->
        <el-form-item>
          <el-button type="primary" @click="onQuery" icon="el-icon-search">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="list-main">
      <div class="tools">
        <div class="tools-left">
          <el-button plain type="primary" @click="$refs.addModifyAccountDialog.show()" icon="el-icon-plus">新增
          </el-button>
          <el-button v-if="false" plain type="danger" @click="onDelete" icon="el-icon-delete" :loading="deleteLoading">删除</el-button>
        </div>
        <div class="tools-right">
          <div class="table-tool" @click="getData">
            <i class="el-icon-refresh"></i>
          </div>
        </div>
      </div>
      <div class="table-container">
        <!-- 表头固定不动，要设置height=100%,父元素div要设置flex:1-->
        <el-table
            :data="tableData"
            border
            stripe
            height="100%"
            v-loading="listLoading"
            @selection-change="handleSelectionChange"
        >
          <template slot="empty">
            <el-empty description="暂无数据"></el-empty>
          </template>
          <el-table-column
              type="selection"
              width="55">
          </el-table-column>
          <el-table-column
              prop="userName"
              label="账号"
              align="center"
          >
          </el-table-column>
          <el-table-column
              prop="realName"
              label="姓名"
              align="center"
          >
          </el-table-column>
          <!--          <el-table-column-->
          <!--              prop="TRADE_TIME"-->
          <!--              label="角色"-->
          <!--              align="center"-->
          <!--          >-->
          <!--          </el-table-column>-->
          <el-table-column
              prop="phone"
              label="手机号"
              align="center">
          </el-table-column>
          <el-table-column
              prop="state"
              label="状态"
              align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.state==1">正常</span>
              <span v-else>禁用</span>
            </template>
          </el-table-column>
          <el-table-column
              prop="updatedAt"
              label="上次登录时间"
              align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.updatedAt | timeFormat('MM-DD HH:mm') }}</span>
            </template>
          </el-table-column>
          <el-table-column
              prop="createdAt"
              label="创建时间"
              align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.createdAt | timeFormat('MM-DD HH:mm') }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button
                  type="text"
                  @click="handleEdit(scope.$index, scope.row)">编辑
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pagination.pageIndex"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pagination.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pagination.total">
      </el-pagination>
    </div>
    <AddModifyAccountDialog :reload="onQuery" ref="addModifyAccountDialog"/>
    <ConfirmDialog :callBack="onCallBack" ref="confirmDialog"/>
  </div>

</template>

<script>
import AddModifyAccountDialog from "@/components/account/AddModifyAccountDialog";
import axios from "@/net/axios";
import api from "@/net/api";
import {isNullOrEmpty} from "@/utils";
import ConfirmDialog from "@/components/common/ConfirmDialog";

export default {
  name: "AccountList",
  components: {AddModifyAccountDialog, ConfirmDialog},
  data() {
    return {
      formInline: {
        keywords: '',
        roleType: '',
      },
      pagination: {
        pageIndex: 1,
        pageSize: 10,
        total: 0,
      },
      tableData: [],
      multipleSelection: [],
      listLoading: false,
      deleteLoading: false,
    }
  },
  methods: {
    handleSizeChange(val) {
      this.pagination.pageSize = val;
      this.pagination.pageIndex = 1;
      this.getData()
    },
    handleCurrentChange(val) {
      this.pagination.pageIndex = val
      this.getData()
    },
    handleEdit(index, row) {
      this.$refs.addModifyAccountDialog.show(row)
    }, 
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    getData() {
      let params = {
        pageIndex: this.pagination.pageIndex,
        pageSize: this.pagination.pageSize,
        keywords: this.formInline.keywords,
      }
      this.listLoading = true
      axios.post(api.userList, params)
          .then(res => {
            this.listLoading = false
            this.dialogVisible = false
            if (this.pagination.pageIndex == 1) {
              this.tableData = res.data.rows;
            } else {
              this.tableData.concat(res.data.rows)
            }
            this.pagination.total = res.data.total;
          })
          .catch(e => {
            this.listLoading = false
          })
    }, 
    onDelete() {
      if (isNullOrEmpty(this.multipleSelection)) {
        this.$message.warning('请选择要删除的数据')
        return;
      }
      this.$refs.confirmDialog.show('确定要删除吗？')
    },
    onCallBack() {
      const ids = this.multipleSelection.map(item => item.id)
      this.deleteLoading=true
      axios.post(api.deleteAccount, {ids})
          .then(res => {
            this.deleteLoading=false
            res.message && this.$message.success(res.message)
            this.onQuery();
          })
          .catch(e => {
            this.deleteLoading=false
            e.message && this.$message.error(e.message)
          })
    },
    onQuery() {
      this.pagination.pageIndex = 1;
      this.pagination.total = 0;
      this.getData();
    }
  },
  mounted() {
    this.getData()
  }
}
</script>

<style scoped lang="less">
@import "../../assets/style/list";
</style>
