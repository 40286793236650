<template>
  <router-view/>
</template>

<script> 
export default {
  name: "Advertise"
}
</script>

<style scoped> 
</style>
