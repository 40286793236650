<template>
  <el-dialog
      title="提示"
      :visible.sync="centerDialogVisible"
      width="30%"
      center>
    <span>{{content}}</span>
    <span slot="footer" class="dialog-footer">
    <el-button @click="centerDialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="clickOk">确 定</el-button>
  </span>
  </el-dialog>
</template>

<script>
export default {
  name: "ConfirmDialog",
  props: ['callBack'],
  data() {
    return {
      centerDialogVisible: false,
      content: '',
    }
  },
  methods: {
    show(content) {
      this.centerDialogVisible = true
      this.content = content
    },
    clickOk() {
      this.centerDialogVisible = false
      this.callBack()
    }
  }
}
</script>

<style scoped>

</style>
