<template>
  <div class="edit-container">
    <div class="msg-view">
      <div class="msg-title">
        <i class="el-icon-info"></i>
        <span>基本信息</span>
      </div>
      <el-form :model="formData" :rules="rules" ref="formData" label-width="100px" class="formData"
               :inline-message="true">
        <el-form-item label="名称:" prop="name">
          <el-input v-model="formData.name"></el-input>
        </el-form-item>
        <el-form-item label="案例类型:" prop="name">
          <el-select v-model="formData.caseType" placeholder="案例类型">
            <el-option v-for="item in caseTypeList" :label="item.name" :value="item.id" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="案例简介:" prop="summary">
          <el-input type="textarea" v-model="formData.summary"></el-input>
        </el-form-item>
        <el-form-item label="主图:" prop="mainImg">
          <el-upload
              :action="uploadUrl()"
              list-type="picture-card"
              :show-file-list="false"
              :headers="getHeaders()"
              :on-success="handleSuccess"
              :before-upload="beforeUpload"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove">
            <img v-if="formData.mainImg" :src="formData.mainImg" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
          <span class="tips">图像大小：400*225</span>
        </el-form-item>
        <el-form-item label="排序:" prop="sort">
          <el-input v-model.number="formData.sort" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="是否推荐:">
          <el-switch v-model="formData.recommend"></el-switch>
        </el-form-item>
        <el-form-item label="状态:">
          <el-switch v-model="formData.state"></el-switch>
        </el-form-item>
      </el-form>
    </div>
    <div class="msg-view">
      <div class="msg-title">
        <i class="el-icon-info"></i>
        <span>案例描述</span>
      </div>
      <el-form :model="formData" label-width="100px" class="formData"
               :inline-message="true">
        <el-form-item class="quill-content">
          <quill-editor v-model="formData.content"
                        ref="myQuillEditor"
                        :options="formData.editorOption"
                        @blur="onEditorBlur($event)"
                        @focus="onEditorFocus($event)"
                        @ready="onEditorReady($event)"
          >
          </quill-editor>
          <el-upload style="display:none"
                     :action="uploadUrl()"
                     :headers="getHeaders()"
                     :multiple="false"
                     :show-file-list="false"
                     :on-success='newEditorSuccess'
                     ref="uniqueId"
                     id="uniqueId"
          >
          </el-upload>
        </el-form-item>
        <el-form-item>
          <el-button plain type="primary" @click="submitForm('formData')">提交</el-button>
          <el-button plain @click="goBack">返回</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
/**
 * 添加修改案例
 */
import Quill from 'quill'
import {Loading} from "element-ui";
import axios from "@/net/axios";
import api from "@/net/api";
import {isNullOrEmpty} from "@/utils";
import pubsub from 'pubsub-js'
export default {
  name: "AddModifyCase",
  data() {
    return {
      formData: {
        recommend: false,
        state: true,
        name: '',
        summary: '',
        imageFile: [],
        imgData: {
          fileId: '',
          id: '',
          url: '',
          showImageUrl: '',
        },
        content: '',
        editorOption: {
          theme: 'snow'
        },
        sort: 0,
        caseType: '',
        mainImg: null,
        id: null,
      },
      rules: {
        name: [
          {required: true, message: '请输入案例名称', trigger: 'blur'},
          {min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'blur'}
        ],
        caseType: [
          {required: true, message: '请选择案例类型', trigger: 'blur'}
        ],
        summary: [
          {required: true, message: '请输入案例简介', trigger: 'blur'},
          {min: 1, max: 200, message: '长度在 1 到 200 个字符', trigger: 'blur'}
        ],
        mainImg: [
          {required: true, message: '请上传主图', trigger: 'blur'}
        ],
        sort: [
          {type: 'number', message: '排序必须为数字值'}
        ],
      },
      caseTypeList: []
    }
  },
  computed: {
    editor() {
      return this.$refs.myQuillEditor.quill
    }
  },
  mounted() {
    const imgHandler = async function (state) {
      if (state) {
        const fileInput = document.querySelector('#uniqueId input') //隐藏的file元素
        fileInput.click() //触发事件
      }
    }
    this.$refs.myQuillEditor.quill.getModule("toolbar").addHandler("image", imgHandler)
    // console.log('this.$route.query', this.$route.query)
    if (!isNullOrEmpty(this.$route.query)) {
      this.formData.name = this.$route.query.name;
      this.formData.sort = this.$route.query.sort;
      this.formData.id = this.$route.query.id;
      this.formData.summary = this.$route.query.summary;
      this.formData.mainImg = this.$route.query.mainImg;
      this.formData.content = this.$route.query.content;
      this.formData.caseType = this.$route.query.typeId;
      this.formData.enable = this.$route.query.state == 1;
      this.formData.recommend = this.$route.query.recommend == 1;
    }
    this.loadCaseTypeList()
  },
  methods: {
    goBack() { 
      pubsub.publish('closeTab',this.$route.path)
    },
    uploadUrl() {
      return location.origin + api.uploadFile;
    },
    getHeaders() {
      return {authorization: window.token}
    },
    handleSuccess(res, file) {
      console.log('处理图片上传完成', res, file)
      if (file.raw.isFlag) { 
        this.formData.mainImg = res.data.url;
      } 
    },
    handlePictureCardPreview(file) {
      console.log('handlePictureCardPreview', res, file)
    },
    handleRemove(file, fileList) {

    },
    onEditorBlur(quill) {
      console.log('editor blur!', quill)
    },
    onEditorFocus(quill) {
      console.log('editor focus!', quill)
    },
    onEditorReady(quill) {
      console.log('editor ready!', quill)
    },
    onEditorChange({quill, html, text}) {
      console.log('editor change!', quill, html, text)
      this.content = html
    },
    //上传图片回调
    newEditorSuccess(response, file, fileList) {
      console.log('编辑器上传图片', response, file, fileList)
      if (response.errno == 0) {
        this.addImgRange = this.$refs.myQuillEditor.quill.getSelection()
        this.$refs.myQuillEditor.quill.insertEmbed(this.addImgRange != null ? this.addImgRange.index : 0, 'image', response.data.url, Quill.sources.USER)
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.formData.id) {
            this.updateCase();
          } else {
            this.createCase();
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    createCase() {
      let loadingInstance = Loading.service({background: 'rgba(0, 0, 0, 0.3)'});
      axios.post(api.createCase, this.formData)
          .then(res => {
            loadingInstance.close();
            res.message && this.$message.success(res.message);
            this.dialogVisible = false
            pubsub.publish('closeTab',this.$route.path)
            this.$bus.$emit('reloadCaseList')
          })
          .catch(e => {
            loadingInstance.close();
            e.message && this.$message.error(e.message);
          })
    },
    updateCase() {
      let loadingInstance = Loading.service({background: 'rgba(0, 0, 0, 0.3)'});
      axios.post(api.updateCase, {...this.formData, state: this.formData.enable ? 1 : 2})
          .then(res => {
            loadingInstance.close();
            res.message && this.$message.success(res.message);
            this.dialogVisible = false
            pubsub.publish('closeTab',this.$route.path)
            this.$bus.$emit('reloadCaseList')
          })
          .catch(e => {
            loadingInstance.close();
            e.message && this.$message.error(e.message);
          })
    },
    loadCaseTypeList() {
      axios.get(api.getEnableList)
          .then(res => {
            this.caseTypeList = res.data;
          })
          .catch(console.log)
    },
    //图片上传前的回调函数
    beforeUpload(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/jpg" || file.type === "image/png";
      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG和PNG 格式!");
      }
      //调用[限制图片尺寸]函数
      this.limitFileWH(400, 225, file).then((res) => {
        file.isFlag = res
      })
      return isJPG;
    },
    //限制图片尺寸
    limitFileWH(E_width, E_height, file) {
      let _this = this;
      let imgWidth = "";
      let imgHight = "";
      const isSize = new Promise(function(resolve, reject) {
        let width = E_width;
        let height = E_height;
        let _URL = window.URL || window.webkitURL;
        let img = new Image();
        img.onload = function() {
          imgWidth = img.width;
          imgHight = img.height;
          let valid = img.width == width && img.height == height;
          valid ? resolve() : reject();
        }
        img.src = _URL.createObjectURL(file);
      }).then(() => {
        return true;
      }, () => {
        _this.$message.warning({
          message: '上传文件的图片大小不合符标准,宽需要为' + E_width + 'px，高需要为' + E_height + 'px。当前上传图片的宽高分别为：' + imgWidth + 'px和' +
              imgHight + 'px',
          btn: false
        })
        return false;
      });
      return isSize
    }
  },
}
</script>

<style scoped lang="less">
.edit-container {
  padding: 20px;
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
}

.msg-view {
  flex: 1;
}

.msg-title {
  padding-bottom: 10px;
  border-bottom: 1px solid #E4E7ED;
  width: 100%;
  font-weight: bold;
  font-size: 14px;

  span {
    margin-left: 10px;
  }
}

.formData {
  padding: 30px 0 10px 0;
}

.el-input {
  /deep/ .el-input__inner {
    width: 300px;
  }
}

.el-textarea {
  /deep/ .el-textarea__inner {
    width: 500px;
  }
}

.quill-editor {
  /deep/ .ql-container {
    min-height: 300px !important;
  }
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 148px;
  height: 148px;
  line-height: 148px;
  text-align: center;
}

.avatar {
  width: 148px;
  height: 148px;
  display: block;
}
.tips{
  font-size: 12px;
  color: #999;
  margin-top: 10px;
}
</style>
